import React, { useState, useEffect } from "react";
import { ITokenPropType } from "../../interfaces";
import axios from "axios";
import { post } from "../../utils/api_service";
import * as urls from "../../utils/url";
import { CircularProgress } from "react-loading-indicators";

const apiKey =
  "dde7f94ff025ef674aec2db1f98be003446ebf68520e6a475285ae23b801e9ae";
const imageUrl = "https://blockchain.thenineties.tech/img/";

const Token = ({
  onSelect,
  selected,
  token,
  chooseSelected,
  chatId,
  loadNotifications,
  inSubscribed,
  selectedNetwork
}: ITokenPropType) => {
  const [price, setPrice] = useState("...");
  const [imageError, setImageError] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await loadNotifications(selectedNetwork);
    await chooseSelected(token._id, selectedNetwork);
    onSelect({
      subscribed,
      token,
    });
    setLoading(false);
  };

  useEffect(() => {
    const getPrice = async () => {
      try {
        const res = await axios.get(
          `https://min-api.cryptocompare.com/data/price?fsym=${token.symbol}&tsyms=USD&api_key=${apiKey}`
        );
        setPrice(`${res.data.USD ? `$${res.data.USD}` : ""}`);
      } catch (e) {
        console.log(e);
      }
    };
    getPrice();
  }, [token.symbol]);

  useEffect(() => {
    if (inSubscribed) {
      setSubscribed(true);
    } else {
      const checkSubscription = async () => {
        const res = await post(urls.checkSubscription, {
          chatId: chatId,
          tokenId: token._id,
        });
        setSubscribed(res.data);
      };
      checkSubscription();
    }
  }, [chatId, selected]);

  return (
    <div
      className="token"
      style={
        selected?.token === token
          ? { border: "3px solid #37474f" }
          : subscribed
          ? { backgroundColor: "#37474f" }
          : {}
      }
      onClick={handleClick}
    >
      {loading ? (
        <CircularProgress
          style={{ fontSize: 10, height: 30, width: 30, marginBottom: 5 }}
          color="white"
        />
      ) : imageError || !token.image ? (
        <div className="icon">
          <p>{token.symbol?.charAt(0).toUpperCase()}</p>
        </div>
      ) : (
        <img
          className="token-icon"
          src={`${imageUrl}${token.image}`}
          alt={token.image}
          onError={() => setImageError(true)}
        />
      )}

      <h4>{token.symbol?.toUpperCase()}</h4>
      <p>{price}</p>
    </div>
  );
};

export default Token;
