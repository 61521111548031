import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import Fab from "@mui/material/Fab";
import { useContext, useEffect, useState } from "react";
import { CircularProgress } from "react-loading-indicators";
import Whale from "../components/Whales/Whale";
import { webAppContext } from "../contexts/appContext";
import { get, post } from "../utils/api_service";
import * as urls from "../utils/url";
import { IWhaleType } from "../interfaces";
import WhaleAction from "../components/Whales/WhaleAction";
import WhaleUpdateAction from "../components/Whales/WhaleUpdateAction";

const Whales = ({
  setBottomIndex,
  networks,
}: {
  setBottomIndex: any;
  networks: any[];
}) => {
  const app = useContext(webAppContext);
  const [whales, setWhales] = useState<IWhaleType[]>([]);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [availableTokens, setAvailableTokens] = useState([]);
  const [selected, setSelected] = useState({ tokens: [] });
  const [updateOpen, setUpdateOpen] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState(networks[0]?.networkId);
  const chatId = app?.initDataUnsafe?.user?.id;
  // const chatId = "1576208905";

  const selectWhale = (whale: any) => {
    setSelected(whale);
    setUpdateOpen(true);
  };

  const addWhale = async (address: string, name: string, tokens: any[]) => {
    setActionLoading(true);
    try {
      const data = { chatId, name, address, tokens };
      const res = await post(`${urls.addWhale}/${selectedNetwork}`, data);
      if (res.status) {
        setWhales(res.data);
        setOpen(false);
      }
    } catch (e) {
      console.log(e);
    }
    setActionLoading(false);
  };

  const loadTokens = async () => {
    try {
      const res = await get(urls.available);
      setAvailableTokens(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  const loadWhales = async () => {
    try {
      const res = await get(`${urls.whales}/${chatId}`);
      setWhales(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  // useEffect(() => {
  //   const loadData = async () => {
  //     setLoading(true);
  //     await loadWhales();
  //     await loadTokens();
  //     setLoading(false);
  //   };
  //   loadData();
  // }, []);

  useEffect(() => {
      const loadData = async () => {
          setLoading(true)
          await loadWhales()
          await loadTokens()
          setLoading(false)
      }
      if (app.version) {
          loadData();
      }
  }, [app.version]);

  return (
    <>
      <div className="container">
        <div className="backToTokens" onClick={() => setBottomIndex(0)}>
          <FontAwesomeIcon
            icon={faCircleChevronLeft}
            color="white"
            fontSize="18px"
          />
          <p>Tokens</p>
        </div>
        <h2 style={{ color: "white" }}>Track Whales</h2>
        <div className="content-whale">
          {loading ? (
            <div className="info">
              <CircularProgress
                style={{ fontSize: 10, height: 30, width: 30 }}
                color="white"
              />
            </div>
          ) : whales.length === 0 ? (
            <div className="info">
              <h4 style={{ color: "white" }}>No whales found.</h4>
            </div>
          ) : (
            whales.map((e: any) => (
              <Whale
                selectWhale={selectWhale}
                whale={e}
                availableTokens={availableTokens}
                key={e._id}
              />
            ))
          )}
        </div>
      </div>
      <div className="floating-button">
        <Fab variant="extended" color="default" onClick={() => setOpen(true)}>
          <FontAwesomeIcon icon={faPlusCircle} color="black" fontSize="22px" />
          <h4>Whale</h4>
        </Fab>
      </div>
      <WhaleAction
        open={open}
        setOpen={setOpen}
        actionLoading={actionLoading}
        availableTokens={availableTokens}
        addWhale={addWhale}
        networks={networks}
        selectedNetwork={selectedNetwork}
        setSelectedNetwork={setSelectedNetwork}
      />

      <WhaleUpdateAction
        chatId={chatId}
        setWhales={setWhales}
        open={updateOpen}
        setOpen={setUpdateOpen}
        availableTokens={availableTokens}
        selected={selected}
        networks={networks}
        selectedNetwork={selectedNetwork}
        setSelectedNetwork={setSelectedNetwork}
        actionLoading={actionLoading}
        addWhale={addWhale}
      />
    </>
  );
};

export default Whales;
