export const baseUrl = "https://k4jws6x71f.execute-api.ap-northeast-1.amazonaws.com/test/api/"
// export const baseUrl = "http://192.168.1.64:4000/api/"
export const available = "tokens"
export const availablePaginate = "tokens/paginate"
export const subscribed = "token/subscribed"
export const subscribe = "token/subscribe/"
export const unsubscribe = "token/unsubscribe/"
export const search = "token/search"
export const notifications = "notifications"
export const updateSubscription = "token/update/"
export const whales = "whale/subscribed"
export const addWhale = "whale/add"
export const updateWhale = "whale/update"
export const deleteWhale = "whale/delete"
export const networks = "networks"
export const checkSubscription = "token/check-subscription"
export const getSelectedNotification = "token/subscribed-notifications/"
export const isTokenSubscribed = "issubscribed/token"
export const isWhaleSubscribed = "issubscribed/whale"
export const whaleTokens = "whale/tokens/"