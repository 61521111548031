import Box from "@mui/material/Box";
import { CircularProgress } from "react-loading-indicators";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { ITokenType } from "../../interfaces";
import WhaleToken from "./WhaleToken";
import { ethers } from "ethers";
import SelectTokenModal from "./SelectTokenModal";
import NetworkSelector from "../NetworkSelector";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  backgroundColor: "#1e1e1e",
  border: "none",
  borderRadius: "12px",
  boxShadow: 24,
  p: 2,
  outline: "none",
};

const WhaleAction = ({
  open,
  actionLoading,
  setOpen,
  availableTokens,
  addWhale,
  networks,
  selectedNetwork,
  setSelectedNetwork
}: any) => {
  const [selectTokens, setSelectTokens] = useState(false);
  const [selectedTokens, setSelectedTokens] = useState<any[]>([]);
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState(false);
  const [showError, setShowError] = useState(false);

  const getSymbol = (id: String) => {
    const i = availableTokens.findIndex((e: ITokenType) => e._id === id);
    const token = availableTokens[i];
    return token.symbol;
  };

  const handleAddressChange = (address: string) => {
    setAddress(address);
    setError(!ethers.utils.isAddress(address));
  };

  const submit = () => {
    setShowError(true);
    if (
      ethers.utils.isAddress(address) &&
      name !== "" &&
      selectedTokens.length > 0
    ) {
      addWhale(address, name, selectedTokens);
    }
  };

  const handleChange = (e:any) => {
    setSelectedNetwork(e.target.value)
  };

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <h3 style={{ color: "white" }}>Add Whale</h3>
          <NetworkSelector
            handleChange={handleChange}
            networks={networks}
            selectedNetwork={selectedNetwork}
          />
          <input
            style={{
              borderBottom: showError && name === "" ? "2px solid red" : "",
            }}
            value={name}
            onChange={(e: any) => setName(e.target.value)}
            type="text"
            placeholder="Whale Name"
            className="whaleAddressInput"
          />
          {showError && name === "" && (
            <p className="errorMsg" style={{ textAlign: "left" }}>
              Enter a name for whale
            </p>
          )}
          <input
            style={{ borderBottom: error ? "2px solid red" : "" }}
            value={address}
            onChange={(e: any) => handleAddressChange(e.target.value)}
            type="text"
            placeholder="Whale Address"
            className="whaleAddressInput"
          />
          {error && (
            <p className="errorMsg" style={{ textAlign: "left" }}>
              Enter a valid address
            </p>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 12,
            }}
          >
            <h4 style={{ color: "white" }}>Tokens</h4>
            <h5
              onClick={() => setSelectTokens(true)}
              style={{ color: "lightblue" }}
            >
              Add Token
            </h5>
          </div>
          {selectedTokens.length === 0 ? (
            <div className="info">
              <p style={{ fontSize: "12px", color: "lightgrey" }}>
                Please select at least one token
              </p>
            </div>
          ) : (
            <div className="selectTokens">
              {selectedTokens.map((e: any) => {
                return (
                  <WhaleToken
                    selectedTokens={selectedTokens}
                    setSelectedTokens={setSelectedTokens}
                    item={e}
                    getSymbol={getSymbol}
                    key={e.id}
                  />
                );
              })}
            </div>
          )}
          <button className="actionButton" onClick={() => submit()}>
            {actionLoading ? (
              <CircularProgress
                style={{ fontSize: 10, height: 20, width: 20 }}
                color="white"
              />
            ) : (
              "Add Whale"
            )}
          </button>
          <h4
            onClick={() => setOpen(false)}
            style={{ textAlign: "center", color: "white" }}
          >
            Cancel
          </h4>
        </Box>
      </Modal>
      <SelectTokenModal
        selectTokens={selectTokens}
        setSelectTokens={setSelectTokens}
        availableTokens={availableTokens}
        selectedTokens={selectedTokens}
        setSelectedTokens={setSelectedTokens}
      />
    </>
  );
};

export default WhaleAction;
