import {Avatar, AvatarGroup} from "@mui/material";
interface IProps{
    availableTokens: any[],
    whale: any,
    selectWhale: any
}
const Whale = ({availableTokens, whale, selectWhale}: IProps) => {
    const imageUrl = "https://blockchain.thenineties.tech/img/"

    const getImageName = (address: string) => {
        const i = availableTokens.findIndex((e: any) => e.address === address)
        return availableTokens[i].image
    }

    return (
        <div className='whale' onClick={() => selectWhale(whale)}>
            <h3>{whale.name}</h3>
            <h4 style={{overflowWrap: 'break-word'}}>{whale.address}</h4>
            <AvatarGroup max={10} spacing='medium' sx={{
                mt: 1,
                "& .MuiOutlinedInput-notchedOutline": {
                    border: "none"
                },
                "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: "none"
                    }
                },
                '& .MuiAvatar-root': {border: 'none', width: 30, height: 30, fontSize: 12, color: 'black', flexDirection: 'row'},
            }}>
                {/* {whale.tokens.map((token: any) => {
                    return <Avatar
                        sx={{width: 30, height: 30}}
                        key={token._id}
                        alt={token.symbol}
                        src={`${imageUrl}${getImageName(token.address)}`}/>
                })} */}
            </AvatarGroup>
        </div>
    );
}

export default Whale;