import { CircularProgress } from "react-loading-indicators";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { ImageList, ImageListItem } from "@material-ui/core";
import { ISelectedType, ITokenType } from "../interfaces";
import Token from "../components/Tokens/Token";
import Action from "../components/Tokens/Action";
import { get, post } from "../utils/api_service";
import * as urls from "../utils/url";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { webAppContext } from "../contexts/appContext";

const Tokens = ({ networks }: { networks: any[] }) => {
  const app = useContext(webAppContext);
  const [loading, setLoading] = useState(false);
  const [subscribedLoading, setSubscribedLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [available, setAvailable] = useState<ITokenType[]>([]);
  const [subscribed, setSubscribed] = useState<ITokenType[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selected, setSelected] = useState<ISelectedType>();
  const [searchString, setSearchString] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [notifications, setNotifications] = useState<any>([]);
  const [selectedNotifications, setSelectedNotifications] = useState<any[]>([]);
  const [showError, setShowError] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState(networks[0]?.networkId);
  const observer = useRef<any>();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);
  const chatId = app?.initDataUnsafe?.user?.id;
  // const chatId = "1576208905";

  const loadAvailable = async (paginate = false) => {
    try {
      setLoading(!paginate);
      setPageLoading(paginate);
      const res = await get(`${urls.availablePaginate}?page=${page}`);
      if (page === 1) {
        setAvailable(res.data);
      } else {
        setAvailable((pre) => [...pre, ...res.data]);
      }
      setPage((pre) => pre + 1);
      setTotalPage(res.totalPages);
    } catch (e) {
      console.log(e);
    }
    setPageLoading(false);
    setLoading(false);
  };

  const lastBookElementRef = useCallback(
    (node: any) => {
      if (pageLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (page <= totalPage) {
            loadAvailable(true);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [pageLoading, totalPage]
  );

  const loadSubscribed = async () => {
    try {
      setSubscribedLoading(true);
      const res = await get(`${urls.subscribed}/${chatId}`);
      setSubscribed(res.data);
    } catch (e) {
      console.log(e);
    }
    setSubscribedLoading(false);
  };

  const search = async () => {
    try {
      setSearchLoading(true);
      const res = await post(urls.search, { name: searchString });
      setAvailable(res.data);
    } catch (e) {
      console.log(e);
    }
    setSearchLoading(false);
  };

  const checkError = () => {
    let error = false;
    selectedNotifications.forEach((item) => {
      error = item.minAmount
        ? item.minAmount < selected?.token.minlimit!
        : true;
    });
    return error;
  };

  const subscribe = async () => {
    setIsError(checkError());
    setShowError(true);
    if (selectedNotifications.length !== 0 && !checkError()) {
      try {
        setActionLoading(true);
        const res = await post(`${urls.subscribe}${selectedNetwork}`, {
          chatId: chatId,
          tokenId: selected?.token._id,
          subscriptions: selectedNotifications,
        });
        if (res.status) {
          setSubscribed([...subscribed, res.data]);
          setSelected(undefined);
          setShowError(false);
        }
      } catch (e) {
        console.log(e);
      }
      setActionLoading(false);
    }
  };

  const chooseSelected = async (tokenId: string, networkId: string) => {
    setSelectedNotifications(await getSelectedNotifications(tokenId, networkId));
  };

  const unsubscribe = async () => {
    try {
      setActionLoading(true);
      const res = await post(`${urls.unsubscribe}${selectedNetwork}`, {
        chatId: chatId,
        tokenId: selected?.token._id,
      });
      if (res.status) {
        loadSubscribed()
        setSelected(undefined);
      }
    } catch (e) {
      console.log(e);
    }
    setActionLoading(false);
  };

  const update = async () => {
    setIsError(checkError());
    setShowError(true);
    if (selectedNotifications.length !== 0 && !checkError()) {
      setUpdateLoading(true);
      try {
        const res = await post(
          `${urls.updateSubscription}${chatId}/${selectedNetwork}`,
          {
            tokenId: selected?.token._id,
            subscriptions: selectedNotifications,
          }
        );
        if (res.status) {
          setSelected(undefined);
          setShowError(false);
          setSelectedNotifications([]);
        }
      } catch (e) {
        console.log(e);
      }
      setUpdateLoading(false);
    }
  };

  const getSelectedNotifications = async (tokenId: string, networkId: string) => {
    try{
    const res = await post(
      `${urls.getSelectedNotification}${networkId}`,
      {
        tokenId: tokenId,
        chatId: chatId,
      }
    );
    return res.data || [];
    }
    catch(e){
      console.log(e)
    }
  };

  const getSelectedNetwork = (networkId: string) => {
    return networks.find((e) => e.networkId === networkId);
  };

  const loadNotification = async (networkId: string) => {
    console.log(getSelectedNetwork(networkId))
    try {
      const baseUrl = `${getSelectedNetwork(networkId).baseUrl}/bot/`;
      const res = await get(urls.notifications, baseUrl);
      setNotifications(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  // useEffect(() => {
  //   loadAvailable();
  //   loadSubscribed();
  // }, []);

  useEffect(() => {
    if (app.version) {
      loadAvailable();
      loadSubscribed();
    }
  }, [app.version]);

  return (
    <>
      <div className="container">
        <div className="search-bar">
          <input
            type="search"
            onChange={(e) => {
              setSearchString(e.target.value);
            }}
            value={searchString}
            className="searchField"
            placeholder="Search by Name"
          />
          <button className="search-button" onClick={() => search()}>
            {searchLoading ? (
              <CircularProgress
                style={{ fontSize: 5, height: 20, width: 20 }}
                color="white"
              />
            ) : (
              <FontAwesomeIcon icon={faSearch} color="white" fontSize="22px" />
            )}
          </button>
        </div>
        <div className="tab">
          <div
            className={selectedTab === 0 ? "tab-item-selected" : "tab-item"}
            onClick={() => setSelectedTab(0)}
          >
            <h4>Available</h4>
          </div>
          <div
            className={selectedTab === 1 ? "tab-item-selected" : "tab-item"}
            onClick={() => setSelectedTab(1)}
          >
            <h4>Subscribed</h4>
          </div>
        </div>
        <div className="content">
          {selectedTab === 0 ? (
            loading ? (
              <div className="info">
                <CircularProgress
                  style={{ fontSize: 10, height: 30, width: 30 }}
                  color="white"
                />
              </div>
            ) : available.length === 0 ? (
              <div className="info">
                <h4 style={{ color: "white" }}>No tokens found.</h4>
              </div>
            ) : (
              <>
                <ImageList rowHeight={120} cols={3}>
                  {available.map((data: ITokenType, index: number) => {
                    if (available.length === index + 1) {
                      return (
                        <ImageListItem ref={lastBookElementRef} key={data._id}>
                          <Token
                            onSelect={setSelected}
                            selected={selected}
                            token={data}
                            chooseSelected={chooseSelected}
                            chatId={chatId}
                            loadNotifications={loadNotification}
                            inSubscribed={false}
                            selectedNetwork={selectedNetwork}
                          />
                        </ImageListItem>
                      );
                    } else {
                      return (
                        <ImageListItem key={data._id}>
                          <Token
                            onSelect={setSelected}
                            selected={selected}
                            token={data}
                            chooseSelected={chooseSelected}
                            chatId={chatId}
                            loadNotifications={loadNotification}
                            inSubscribed={false}
                            selectedNetwork={selectedNetwork}
                          />
                        </ImageListItem>
                      );
                    }
                  })}
                </ImageList>
                {pageLoading && (
                  <div className="info">
                    <CircularProgress
                      style={{ fontSize: 10, height: 30, width: 30 }}
                      color="white"
                    />
                  </div>
                )}
              </>
            )
          ) : subscribedLoading ? (
            <div className="info">
              <CircularProgress
                style={{ fontSize: 10, height: 30, width: 30 }}
                color="white"
              />
            </div>
          ) : subscribed.length === 0 ? (
            <div className="info">
              <h4 style={{ color: "white" }}>No tokens found.</h4>
            </div>
          ) : (
            <ImageList rowHeight={120} cols={3}>
              {subscribed.map((data: ITokenType) => (
                <ImageListItem key={data._id}>
                  <Token
                    onSelect={setSelected}
                    selected={selected}
                    token={data}
                    chooseSelected={chooseSelected}
                    chatId={chatId}
                    loadNotifications={loadNotification}
                    inSubscribed={true}
                    selectedNetwork={selectedNetwork}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          )}
        </div>
      </div>
      {selected && (
        <Action
          selected={selected}
          actionLoading={actionLoading}
          isError={isError}
          subscribe={subscribe}
          unsubscribe={unsubscribe}
          setIsError={setIsError}
          setActionLoading={setActionLoading}
          setSelected={setSelected}
          notifications={notifications}
          setSelectedNotifications={setSelectedNotifications}
          selectedNotifications={selectedNotifications}
          showError={showError}
          setShowError={setShowError}
          update={update}
          updateLoading={updateLoading}
          networks={networks}
          selectedNetwork={selectedNetwork}
          setSelectedNetwork={setSelectedNetwork}
          loadNotification={loadNotification}
          loadSelectedNotification={chooseSelected}
          chatId={chatId}
        />
      )}
    </>
  );
};

export default Tokens;
