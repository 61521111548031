import { useEffect, useState } from "react";
import { ThemeProvider } from "@material-ui/core";
import { baseTheme } from "./theme/Theme-variable";
import Tokens from "./tabs/Tokens";
import BottomBar from "./components/BottomBar";
import Whales from "./tabs/Whales";
import { get } from "./utils/api_service";
import * as urls from "./utils/url";
import { CircularProgress } from "react-loading-indicators";


function App() {
  const [networks, setNetworks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [bottomIndex, setBottomIndex] = useState(0);
  const [value, setValue] = useState(0);

  useEffect(() => {
    const loadNetworks = async () => {
      try {
        const res = await get(urls.networks);
        setNetworks(res.data);
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    };
    loadNetworks();
  }, []);

  return (
    <ThemeProvider theme={baseTheme}>
      {loading ? (
        <div className="info">
          <CircularProgress
            style={{ fontSize: 10, height: 30, width: 30 }}
            color="white"
          />
        </div>
      ) : bottomIndex === 0 ? (
        <Tokens networks={networks} />
      ) : (
        <Whales networks={networks} setBottomIndex={setBottomIndex} />
      )}
      <BottomBar bottomIndex={bottomIndex} setBottomIndex={setBottomIndex} />
    </ThemeProvider>
  );
}

export default App;
