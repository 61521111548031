import TextField from "@mui/material/TextField";
import { MenuItem } from "@material-ui/core";
import { inputLabelClasses } from "@mui/material/InputLabel";

const NetworkSelector = ({handleChange, selectedNetwork, networks}: {handleChange: any, selectedNetwork: string, networks: any[]}) => {
  return (
    <TextField
    select
    variant="filled"
    onChange={handleChange}
    value={selectedNetwork}
    label="Network"
    sx={{ minWidth: "95%", mt: 2 }}
    InputProps={{
      sx: {
        color: "white",
      },
    }}
    InputLabelProps={{
      sx: {
        color: "white",
        [`&.${inputLabelClasses.shrink}`]: {
          color: "white",
        },
      },
    }}
  >
    {networks.map((network: any, index: number) => (
      <MenuItem key={index} value={network.networkId}>
        {network.name}
      </MenuItem>
    ))}
  </TextField>
  )
}

export default NetworkSelector