import {Box} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {useState} from "react";
import {removeFormat, addFormat} from "../../utils/formatter";

const NotificationItem = ({
                              notification,
                              selected,
                              selectedNotifications,
                              setSelectedNotifications,
                              setShowError,
                          }: any) => {
    const i = selectedNotifications.findIndex((e: any) => e.nid === notification._id)
    const [enabled, setEnabled] = useState(i > -1)
    const [isError, setIsError] = useState(false)

    const getSubscriptionAmount = () => {
        if(i > -1){
            return selectedNotifications[i].minAmount
        }
        return ''
    }

    const [amount, setAmount] = useState(addFormat(getSubscriptionAmount()))

    const onAmountChange = (value: any) => {
        if(value.at(-1) !== '.') {
            let amount = removeFormat(value)
            setAmount(addFormat(amount))
            let sns = selectedNotifications;
            if (selected) {
                setIsError(amount < selected.token.minlimit)
                if (amount >= 0) {
                // if (amount >= selected.token.minlimit) {
                    const i = sns.findIndex((e: any) => e.nid === notification._id)
                    if (i > -1) {
                        sns[i].minAmount = amount
                    }
                }
            }
        }
        else{
            setAmount(value)
        }
    }

    const handleCheckBox = (e: any) => {
        let sns = selectedNotifications;
        if (e.target.checked) {
            sns.push({
                nid: notification._id,
                minAmount: amount
            })
            setShowError(false)
        } else {
            sns = sns.filter((e: any) => e.nid !== notification._id)
        }
        setSelectedNotifications(sns)
        setEnabled(e.target.checked)
    }

    return (
        <Box sx={{mt: 1}}>
            <h5 style={{color: enabled ? 'white' : 'grey', marginBottom: '5px'}}>{notification.name}</h5>
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                <Checkbox checked={enabled} onChange={handleCheckBox}/>
                <input
                    type="text"
                    pattern="^[0-9]*[.,]?[0-9]*$"
                    value={amount}
                    onChange={(e) => onAmountChange(e.target.value)}
                    disabled={!enabled}
                    placeholder="Enter minimum amount"
                    className={isError ? 'actionInputError' : 'actionInput'}
                    style={{background: enabled ? '#424242' : '#323232'}}
                />
            </Box>
            {
                isError &&
                <div style={{textAlign: 'left', width: '100%'}}>
                    <p className='errorMsg'>Amount should be at least {selected.token.minlimit}</p>
                </div>
            }
        </Box>)
}

export default NotificationItem