import { CircularProgress } from "react-loading-indicators";
import { Box } from "@mui/material";
import NotificationItem from "./NotificationItem";
import { useState } from "react";
import NetworkSelector from "../NetworkSelector";
import { isTokenSubscribed } from "../../utils/url";
import { post } from "../../utils/api_service";
import { useEffect } from "react";

const Action = ({
  selected,
  actionLoading,
  isError,
  subscribe,
  unsubscribe,
  setIsError,
  setActionLoading,
  setSelected,
  notifications,
  setSelectedNotifications,
  selectedNotifications,
  showError,
  setShowError,
  update,
  updateLoading,
  networks,
  selectedNetwork,
  setSelectedNetwork,
  loadNotification,
  loadSelectedNotification,
  chatId,
}: any) => {
  const [loading, setLoading] = useState(false);
  const [subscribed, setSubscribed] = useState(false);

  const checkIsSubscribed = async (networkId: string) => {
    try {
      const res = await post(`${isTokenSubscribed}/${networkId}`, {
        chatId,
        tokenId: selected.token._id,
      });
      setSubscribed(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const checkStatus = async () => {
      setLoading(true);
      await checkIsSubscribed(selectedNetwork);
      setLoading(false);
    };
    checkStatus();
  }, []);

  const handleChange = async (e: any) => {
    setLoading(true);
    setSelectedNetwork(e.target.value);
    await loadNotification(e.target.value);
    await loadSelectedNotification(selected.token._id, e.target.value);
    await checkIsSubscribed(e.target.value);
    setLoading(false);
  };

  return (
    <div className="popup">
      <div className="action">
        <h4 style={{ color: "white" }}>{selected.token.name}</h4>
        <NetworkSelector
          handleChange={handleChange}
          networks={networks}
          selectedNetwork={selectedNetwork}
        />
        {loading ? (
          <CircularProgress
            style={{
              fontSize: 10,
              height: 30,
              width: 30,
              marginTop: 20,
              marginBottom: 20,
            }}
            color="white"
          />
        ) : selected?.subscribed ? (
          <>
            <Box sx={{ mt: 2, width: "100%" }}>
              <p
                style={{
                  color: "white",
                  fontSize: "12px",
                  marginBottom: "5px",
                  textAlign: "center",
                }}
              >
                Select preferred notifications
              </p>
              {notifications.map((notification: any) => (
                <NotificationItem
                  notification={notification}
                  selected={selected}
                  selectedNotifications={selectedNotifications}
                  setSelectedNotifications={setSelectedNotifications}
                  setShowError={setShowError}
                  key={notification._id}
                />
              ))}
              {showError && selectedNotifications.length === 0 && (
                <div style={{ textAlign: "left", width: "100%" }}>
                  <p className="errorMsg">
                    Please select at least one notification
                  </p>
                </div>
              )}
            </Box>
            {subscribed ? (
              <>
                <button
                  className="actionButton"
                  style={{ marginBottom: 0 }}
                  onClick={() => update()}
                >
                  {updateLoading ? (
                    <CircularProgress
                      style={{ fontSize: 10, height: 20, width: 20 }}
                      color="white"
                    />
                  ) : (
                    "Update"
                  )}
                </button>
                <button className="actionButton" onClick={() => unsubscribe()}>
                  {actionLoading ? (
                    <CircularProgress
                      style={{ fontSize: 10, height: 20, width: 20 }}
                      color="white"
                    />
                  ) : (
                    "Unsubscribe"
                  )}
                </button>
              </>
            ) : (
              <button className="actionButton" onClick={() => subscribe()}>
                {actionLoading ? (
                  <CircularProgress
                    style={{ fontSize: 10, height: 20, width: 20 }}
                    color="white"
                  />
                ) : (
                  "Subscribe"
                )}
              </button>
            )}
          </>
        ) : (
          <>
            <Box sx={{ mt: 2, width: "100%" }}>
              <p
                style={{
                  color: "white",
                  fontSize: "12px",
                  marginBottom: "10px",
                  textAlign: "center",
                }}
              >
                Select preferred notifications
              </p>
              {notifications.map((notification: any) => (
                <NotificationItem
                  notification={notification}
                  selected={selected}
                  selectedNotifications={selectedNotifications}
                  setSelectedNotifications={setSelectedNotifications}
                  setShowError={setShowError}
                  key={notification._id}
                />
              ))}
              {showError && selectedNotifications.length === 0 && (
                <p className="errorMsg">
                  Please select at least one notification
                </p>
              )}
              {showError && selectedNotifications.length !== 0 && isError && (
                <p className="errorMsg">
                  Enter a valid minimum amount for selected notifications
                </p>
              )}
            </Box>

            <button className="actionButton" onClick={() => subscribe()}>
              {actionLoading ? (
                <CircularProgress
                  style={{ fontSize: 10, height: 20, width: 20 }}
                  color="white"
                />
              ) : (
                "Subscribe"
              )}
            </button>
          </>
        )}

        <h4
          style={{ color: "white" }}
          onClick={() => {
            setIsError(false);
            setActionLoading(false);
            setSelected(undefined);
            setShowError(false);
          }}
        >
          Cancel
        </h4>
      </div>
    </div>
  );
};

export default Action;
