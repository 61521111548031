import Box from "@mui/material/Box";
import { ITokenType } from "../../interfaces";
import SelectToken from "./SelectToken";
import Modal from "@mui/material/Modal";
import { useState } from "react";

interface IProps {
  selectTokens: boolean;
  setSelectTokens: any;
  availableTokens: any[];
  selectedTokens: any;
  setSelectedTokens: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  backgroundColor: "#1e1e1e",
  border: "none",
  borderRadius: "12px",
  boxShadow: 24,
  p: 2,
  outline: "none",
};

const SelectTokenModal = ({
  selectTokens,
  setSelectTokens,
  availableTokens,
  selectedTokens,
  setSelectedTokens,
}: IProps) => {
  const [filteredTokens, setFilteredTokens] = useState(availableTokens);
  const [filtered, setFiltered] = useState(false);

  const filter = (e: any) => {
    setFiltered(true);
    if (e.target.value === "" || !e.target.value) {
      setFilteredTokens(availableTokens);
    } else {
      const name = e.target.value.toLowerCase();
      setFilteredTokens(
        availableTokens.filter((e: any) => e.name.toLowerCase().includes(name))
      );
    }
  };

  const selectAll = () => {
    let selected = selectedTokens;
    availableTokens.forEach((token: any) => {
      if (!selected.some((e: any) => e.id === token._id)) {
        selected.push({
          id: token._id,
          minAmount: 0,
        });
      }
    });
    setSelectedTokens(selected);
    setSelectTokens(false);
  };

  return (
    <Modal open={selectTokens} onClose={() => setSelectTokens(false)}>
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          <h3 style={{ color: "white" }}>Select Tokens</h3>
          <p
            onClick={() => selectAll()}
            style={{ color: "white", fontSize: "12px" }}
          >
            Select All
          </p>
        </div>
        <input
          type="text"
          className="searchTokenField"
          onChange={filter}
          placeholder="Filter by name"
        />
        <div className="selectTokens">
          {filtered ? (
            filteredTokens.length === 0 ? (
              <p
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: "12px",
                }}
              >
                Nothing found..
              </p>
            ) : (
              filteredTokens.map((token: ITokenType) => {
                return (
                  <SelectToken
                    token={token}
                    selectedTokens={selectedTokens}
                    setSelectedTokens={setSelectedTokens}
                    key={token._id}
                  />
                );
              })
            )
          ) : (
            availableTokens.map((token: ITokenType) => {
              return (
                <SelectToken
                  token={token}
                  selectedTokens={selectedTokens}
                  setSelectedTokens={setSelectedTokens}
                  key={token._id}
                />
              );
            })
          )}
        </div>
        <button className="actionButton" onClick={() => setSelectTokens(false)}>
          Done
        </button>
      </Box>
    </Modal>
  );
};

export default SelectTokenModal;
