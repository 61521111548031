import axios from "axios";
import { baseUrl } from "./url";

const config = {
  headers: {
    "Access-Control-Allow-Origin": "*",
  }
}

export const get = async ( url: string, base: string = baseUrl) => {
  const res = await axios.get(`${base}${url}`, config);
  return res.data;
};

export const post = async ( url: string, body: any, base: string = baseUrl) => {
    const res = await axios.post(`${base}${url}`, body, config)
    return res.data;
}
