import Checkbox from "@mui/material/Checkbox";
import {ITokenType} from "../../interfaces";
import {useState} from "react";

interface IProps {
    token: ITokenType,
    selectedTokens: any,
    setSelectedTokens: any
}

const SelectToken = ({token, selectedTokens, setSelectedTokens}: IProps) => {
    const [checked, setChecked] = useState(selectedTokens.some((e: any) => e.id === token._id))

    const handleCheck = (e: any, id: string) => {
        let selected = selectedTokens
        if (e.target.checked) {
            selected.push({
                    id: id,
                    minAmount: 0
                }
            )
            setSelectedTokens(selected)
            setChecked(true)
        } else {
            setSelectedTokens(selected.filter((e: any) => e.id !== id))
            setChecked(false)
        }
    }

    return (
        <div className='selectToken' key={token._id}>
            <p>{token.name} ({token.symbol})</p>
            <Checkbox
                checked={checked}
                onChange={(e) => handleCheck(e, token._id)}/>
        </div>
    )
}
export default SelectToken