import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFire, faUserAstronaut } from "@fortawesome/free-solid-svg-icons";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";

const BottomBar = ({ bottomIndex, setBottomIndex }: any) => {
  // return <div className="bottom-bar">
  //     <div className="bottom-item" onClick={() => setBottomIndex(0)}>
  //         <FontAwesomeIcon
  //             icon={faFire}
  //             color={bottomIndex === 0 ? "white" : "grey"}
  //             fontSize="22px"/>
  //         <h5 style={{color: bottomIndex === 0 ? "white" : "grey"}}>Tokens</h5>
  //     </div>
  //     <div className="bottom-item" onClick={() => setBottomIndex(1)}>
  //         <FontAwesomeIcon
  //             icon={faUserAstronaut}
  //             color={bottomIndex === 1 ? "white" : "grey"}
  //             fontSize="22px"/>
  //         <h5 style={{color: bottomIndex === 1 ? "white" : "grey"}}>Whales</h5>
  //     </div>
  // </div>
  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      <BottomNavigation
        value={bottomIndex}
        sx={{ backgroundColor: "#323232;" }}
        onChange={(event, value) => {
          setBottomIndex(value);
        }}
      >
        <BottomNavigationAction
          icon={
            <div>
              <FontAwesomeIcon
                icon={faFire}
                color={bottomIndex === 0 ? "white" : "grey"}
                fontSize="22px"
              />
              <h5
                style={{
                  marginTop: "5px",
                  color: bottomIndex === 0 ? "white" : "grey",
                }}
              >
                Tokens
              </h5>
            </div>
          }
        />
        <BottomNavigationAction
          icon={
            <div>
              <FontAwesomeIcon
                icon={faUserAstronaut}
                color={bottomIndex === 1 ? "white" : "grey"}
                fontSize="22px"
              />
              <h5
                style={{
                  marginTop: "5px",
                  color: bottomIndex === 1 ? "white" : "grey",
                }}
              >
                Whales
              </h5>
            </div>
          }
        />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomBar;
