import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquareMinus} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import {addFormat, removeFormat} from "../../utils/formatter";

interface IProps {
    item: any,
    selectedTokens: any[],
    setSelectedTokens: any,
    getSymbol: any
}

const WhaleToken = ({item, selectedTokens, setSelectedTokens, getSymbol}: IProps) => {
    const [amount, setAmount] = useState(addFormat(item.minAmount))

    const handleAmountChange = (e: any) => {
        if (e.target.value.at(-1) !== '.') {
            let amount = removeFormat(e.target.value)
            if (amount >= 0) {
                setAmount(addFormat(amount))
                let tokens = selectedTokens
                const i = tokens.findIndex((e: any) => e.id === item.id)
                tokens[i].minAmount = amount
                setSelectedTokens(tokens)
            }
        } else {
            setAmount(e.target.value)
        }
    }

    const removeItem = () => {
        setSelectedTokens(selectedTokens.filter((e: any) => e.id !== item.id))
    }

    return (
        <div className='whaleToken'>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <FontAwesomeIcon onClick={() => removeItem()} icon={faSquareMinus} color='red' fontSize={20}/>
                <p>{getSymbol(item.id)}</p>
            </div>
            <input
                value={amount}
                onChange={handleAmountChange}
                placeholder='Minimum amount'
                type="text"
                pattern="^[0-9]*[.,]?[0-9]*$"/>
        </div>
    )
}
export default WhaleToken;